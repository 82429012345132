//縦横中央配置するためのmixin
@mixin center($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

// color
$main-text-color: #403624;
$beige: #F2F2F2;
$beige-dark: #D9D5D2;
$pink: #ffdcd0;
$red: #e50012;
$orange: #ff9400;
$blue: #0000ff;
$gray: #f1f1f1;

body,
a,
a:hover,
a:visited {
  color: $main-text-color;
}

body {
  line-height: 1.6;
  letter-spacing: 1px;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 300;
  font-style: normal;
  overflow-y: scroll;
}

a {
  text-decoration: none;
}

.font-zenkaku-go-300 {
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-weight: 300;
}

.font-zenkaku-go-500 {
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-weight: 500;
}

.lh-16 {
  line-height: 1.6;
}

.lh-18 {
  line-height: 1.8;
}

.ls-2 {
  letter-spacing: 2px;
}

.ls-3 {
  letter-spacing: 2px;
}

a, .a {
  img {
    &:hover {
      opacity: 0.8;
      transition: all 0.5s;
      cursor: pointer;
    }
  }
}

.h-hero {
  height: calc(50vh - 68px);
  width: 100%;
  margin-top: 68px;
}

.bg-hero {
  background: url("../img/img-hero.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-hero-2 {
  background: url("../img/img-hero-2.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.w-tle-hero {
  width: 36vw;
  height: auto;
  max-width: 500px;
  min-width: 300px;
}

.position-tle-hero {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.h-section-tle {
  height: 28px*0.75;
  width: auto;
}

.tle-line-out {
  border: 1px solid #d9d5d2;
  width: 50%;
  height: 1px;
  display: block;
  position: absolute;
  top: 12px;
  right: 0;
}

.tle-line-in {
  border: 1px solid #d9d5d2;
  width: 70%;
  height: 1px;
  display: block;
  position: absolute;
  top: 12px;
  right: 0;
  &:before {
    content: "";
    width: 8px;
    height: 8px;
    background: #B3B3B3;
    border-radius: 16px;
    position: absolute;
    top: -4px;
    left: -1px;
  }
}

.dl-table {
  display: flex;
  flex-flow: column;
  width: 100%;
  dt {
    flex-basis: 15%;
    padding: 1rem 1rem 0;
    border-bottom: none;
    margin: 0;
    &:first-child {
      border-top: 1px solid #B3B3B3;
      + dd{
        border-top: none;
      }
    }
  }
  dd {
    flex-basis: 85%;
    padding: 0.5rem 1rem 1rem;
    border-bottom: 1px solid #B3B3B3;
    margin: 0;
  }
}

.bg-beige {
  background: $beige;
}

.bg-beige-dark {
  background: $beige-dark;
}

.bg-brown {
  background: $main-text-color;
}

.bg-contact {
  background: url("../img/bg-footer.svg");
  background-repeat: repeat-x;
  background-position: center bottom;
  background-size: 200px auto;
  background-color: $beige-dark;
}

.w-recruit-article {
  width: 100%;
  max-width: 464px;
}

.position-recruit-article {
  position: relative;
  left: auto;
  top: auto;
}

.btn-yellow {
  background: #E7F294;
  color: $main-text-color;
  border-radius: 2rem;
  padding: 12px 16px 14px;
  box-shadow: 0px 4px 12px rgba(64,54,36,0.4);
  transition: all 0.5s;
  &:hover {
    text-decoration: none;
    box-shadow: 0px 0px 0px rgba(64,54,36,0.4);
  }
}

.google_map{
  position:relative;
  width:100%;
  height:0;
  padding-top:75%;
  iframe{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    //filter: grayscale(1);
  }
}

.border-gra-brown {
  border: 7px solid rgb(64,54,36);
  border-image: linear-gradient(90deg, rgba(64,54,36,1) 0%, rgba(166,153,143,1) 100%);
  border-image-slice: 1;
}

.worksLightBoxItem {
  img {
    object-fit: cover;
    width: 100%;
    height: 100px;
    overflow: hidden;
  }
}

.lum-lightbox-inner img {
  position: relative;
  width: 100%;
  height: auto;
}
