@include media-breakpoint-up(sm) {
  // collaboration ampisound
  .icon-num {
    left: 2rem;
  }
}

@include media-breakpoint-up(md) {
  h1,
  .h1 {
    font-size: $h1-font-size; //32px
  }
  h2,
  .h2 {
    font-size: $h2-font-size * 1.315; //42px
  }
  .h2-2 {
    font-size: 1.5625rem !important; //25px
  }
  h3,
  .h3 {
    font-size: $h3-font-size; //24px
  }
  h4,
  .h4 {
    font-size: $h4-font-size; //18px
  }
  h5,
  .h5 {
    font-size: $h5-font-size; //17px
  }
  h6,
  .h6 {
    font-size: $h6-font-size; //15px
  }
  p,
  .p,
  li {
    font-size: $font-size-base; //14px
  }

  // hero style
  .hero-text {
    bottom: 4rem;
    img {
      max-width: 370px;
    }
    p {
      letter-spacing: 2px;
      font-size: 1.4rem;
    }
  }

  // footer style
  .footer-logo {
    max-width: 180px;
  }

  footer {
    ul {
      text-align: left;
      padding: 0;
      margin: 0;
      li {
        list-style-type: none;
        & + li {
          margin-top: 0;
          margin-left: 2rem;
        }
        a {
          font-size: 0.875rem !important;
        }
      }
    }
  }

  // about page style
  .about-sec-b-line {
    &::before {
      width: calc(100% * 5);
      left: -50%;
      z-index: -1;
    }
  }
  .about-sec-b-line-reverse {
    &::before {
      width: calc(100% * 5);
      left: auto;
      right: -50%;
      z-index: -1;
    }
  }

  // company style
  .company-dl {
    dl {
      & + dl {
        margin-top: 2.5rem;
      }
    }
  }

  // collaboration ampisound
  .collabo-text {
    height: 120px;
  }

  .icon-num {
    height: 90px;
    left: 1rem;
    top: auto;
    bottom: 1rem;
    transform: translateY(0);
  }

  .agent-name {
    font-size: 1.8rem;
  }

  .agent-img {
    width: 58.7%;
    padding-right: 0.5rem;
  }
  .glasses-img {
    width: 41.3%;
    padding-left: 0.5rem;
  }
  .glasses-details {
    width: 360px;
    border-right: 1px solid #fff;
  }
  .glasses-concept {
    width: calc(100% - 360px);
  }

  // custom order style
  .step-item {
    & + .step-item {
      padding-top: 0;
      padding-left: 2rem;
      &::before {
        content: "";
        display: block;
        top: 50%;
        transform: translate(0, -50%);
        left: 0.75rem;
        width: 10px;
        height: 30px;
        background-image: url(../img/co-arrow-right.png);
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }
  .co-step-title {
    top: -2rem;
    left: -1.5rem;
    font-size: 2.5rem;
  }
}

@include media-breakpoint-up(lg) {
  .worksLightBoxItem {
    img {
      height: 240px;
    }
  }
  .lum-lightbox-inner img {
    position: relative;
    width: auto;
    height: 100%;
  }
  .w-recruit-article {
    width: 40%;
    max-width: 464px;
  }

  .position-recruit-article {
    position: absolute;
    left: 15px;
    top: 10%;
  }
  .dl-table {
    flex-flow: row wrap;
    dt {
      padding: 1rem;
      border-bottom: 1px solid #B3B3B3;
      &:first-child {
        border-top: 1px solid #B3B3B3;
        + dd{
          border-top: 1px solid #B3B3B3;
        }
      }
    }
    dd {
      padding: 1rem;
      border-bottom: 1px solid #B3B3B3;
    }
  }
  .h-section-tle {
    height: 28px;
    width: auto;
  }
  .tle-line-out {
    top: 12px;
  }
  .tle-line-in {
    width: 88%;
    top: 12px;
  }
  .h-hero {
    height: calc(100vh - 68px);
  }
  .position-gallery-thumbs-txt {
    left: auto;
    right: 1rem;
    bottom: 2rem;
  }
  .position-gallery-thumbs-txt-r {
    left: auto;
    right: 1rem;
    bottom: 1rem;
  }
  .gallery-thumbs {
    img {
      &:hover {
        opacity: 0.8;
        transition: all 0.5s;
        cursor: pointer;
      }
    }
    //.swiper-slide {
    //  height: 100%;
    //}
  }
  .glasses-details {
    width: 380px;
  }
  .glasses-concept {
    width: calc(100% - 380px);
  }
  .position-product-name {
    position: absolute;
    left: 2rem;
    right: auto;
    top: 2rem;
    z-index: 10;
  }
  .gallery-top {
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  // custom order style
  .co-step-title {
    top: -2.75rem;
    left: -2rem;
    font-size: 3.5rem;
  }
}

@include media-breakpoint-up(xl) {
}

@include media-breakpoint-up(xxl) {
}
