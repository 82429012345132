// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 0.25rem !default;
$spacers: (
  0: 0,
  1: $spacer * 1,
  2: $spacer * 2,
  3: $spacer * 3,
  4: $spacer * 4,
  5: $spacer * 5,
  6: $spacer * 6,
  7: $spacer * 7,
  8: $spacer * 8,
  9: $spacer * 9,
  10: $spacer * 10,
  11: $spacer * 11,
  12: $spacer * 12,
  13: $spacer * 13,
  14: $spacer * 14,
  15: $spacer * 15,
  16: $spacer * 16,
  17: $spacer * 17,
  18: $spacer * 18,
  19: $spacer * 19,
  20: $spacer * 20,
  22: $spacer * 22,
  24: $spacer * 24,
  26: $spacer * 26,
  28: $spacer * 28,
  30: $spacer * 30,
  32: $spacer * 32,
  34: $spacer * 34,
  36: $spacer * 36,
  38: $spacer * 38,
  40: $spacer * 40,
  42: $spacer * 42,
  44: $spacer * 44,
  46: $spacer * 46,
  48: $spacer * 48,
  50: $spacer * 50,
  n1: $spacer * -1,
  n2: $spacer * -2,
  n3: $spacer * -3,
  n4: $spacer * -4,
  n5: $spacer * -5,
  n6: $spacer * -6,
  n7: $spacer * -7,
  n8: $spacer * -8,
  n9: $spacer * -9,
  n10: $spacer * -10,
  n11: $spacer * -11,
  n12: $spacer * -12,
  n13: $spacer * -13,
  n14: $spacer * -14,
  n15: $spacer * -15,
  n16: $spacer * -16,
  n17: $spacer * -17,
  n18: $spacer * -18,
  n19: $spacer * -19,
  n20: $spacer * -20,
  n22: $spacer * -22,
  n24: $spacer * -24,
  n26: $spacer * -26,
  n28: $spacer * -28,
  n30: $spacer * -30,
  n32: $spacer * -32,
  n34: $spacer * -34,
  n36: $spacer * -36,
  n38: $spacer * -38,
  n40: $spacer * -40,
  n42: $spacer * -42,
  n44: $spacer * -44,
  n46: $spacer * -46,
  n48: $spacer * -48,
  n50: $spacer * -50,
) !default;

// $negative-spacers: if($enable-negative-margins, negativify-map($spacers), null) !default;
// scss-docs-end spacer-variables-maps

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-size-base: 1rem !default; // '16px'

$h1-font-size: $font-size-base * 2.285714286; //32px
$h2-font-size: $font-size-base * 2; //28px
$h3-font-size: $font-size-base * 1.714285714; //24px
$h4-font-size: $font-size-base * 1.285714286; //18px
$h5-font-size: $font-size-base * 1.14286; //16px
$h6-font-size: $font-size-base * 1; // 14px
