.position-hamburger {
  position: absolute;
  top: 0;
  right: 8px;
  z-index: 1000;
  width: 72px;
  height: 72px;
}

.hamburger {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0.25rem 0.25rem 0rem;
  display: block;
  width: 42px;
  height: 34px;
  transform: translate(-50%, -50%);
  &:hover {
    cursor: pointer;
  }

  .line {
    width: 100%;
    height: 2px;
    display: block;
    transition: all 0.3s ease-in-out;
    background: #403624;
  }

  .line:nth-child(1) {
    margin: 0 0 8px;
  }

  .line:nth-child(2) {
    margin: 0 0 8px;
  }

  .line:nth-child(3) {
    margin: 0;
  }

  &.is-active {
    .line:nth-child(1) {
      transform: translateY(13px) rotate(45deg);
    }

    .line:nth-child(2) {
      opacity: 0;
    }

    .line:nth-child(3) {
      transform: translateY(-6px) rotate(-45deg);
    }
  }

  .menuText {
    .txt-menu {
      transition: 1s all;
      opacity: 1;
    }

    .txt-close {
      opacity: 0;
      transition: 1s all;
      transform: translateY(20px);
    }

    &.is-active {
      .txt-menu {
        display: block;
        opacity: 0;
        transform: translateY(20px);
        transition: 1s all;
      }

      .txt-close {
        display: block;
        opacity: 1;
        transform: translateY(0px);
        transition: 1s all;
      }
    }
  }
}

.nav-menu {
  position: fixed;
  top: 72px;
  left: 50vw;
  z-index: 1500;
  width: 50vw;
  height: calc(100vh - 72px);
  transform: translateX(50vw);
  transition: all .3s linear;
  overflow: scroll;
  background: #fff;
  &.is-active {
    transform: translateX(0);
  }
}

.menu__item {
  width: 100%;
  height: auto;
  padding: .5em 1em;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
}

.fixed-header {
  position: fixed;
  top: 1rem;
  left: 1rem;
  transition: all .3s linear;
  width: 100%;
  z-index: 100;
  .nav-item {
    transform: translateX(0);
  }
}

.position-nav-link {
  position: relative;
  top: auto;
  left: 28px;
  transform: translate(0, 0);
}

.line-hover {
  &:before {
    content: "";
    width: 100%;
    height: 1px;
    background: #fff;
    position: absolute;
    left: 0;
    bottom: 47%;
    transform: scale(0, 1) translate(-50%, 0);
    transform-origin: left top;
    transition: transform 0.5s;
  }
  &:hover {
    &:before {
      transform: scale(1, 1);
    }
  }
}

.nav-item,.nav-link {
  height: 15vh;
  min-height: auto;
  border-bottom: 1px solid #d9d5d2;
}

.h-header {
  width: 100%;
  height: 72px;
}

.nav-link {

}

.fv-video-frame {
  background: #006400;
}

.w-header-logo {
  width: auto;
  height: 22px;
}

.h-nav-menu {
  width: auto;
  height: 38px;
}

.position-nav-menu {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.zindex-10 {
  z-index: 10;
}

.zindex-20 {
  z-index: 20;
}

.h-nav-menu {
  height: 16px;
  width: auto;
}

.lh-0 {
  line-height: 0;
}

.font-size-0 {
  font-size: 0;
}

.header-line {
  border: 1px solid #D9D5D2;
  display: none;
  position: relative;
  margin-left: 24px;
  margin-right: 16px;
  &:before {
    content: "";
    width: 8px;
    height: 8px;
    background: #B3B3B3;
    border-radius: 16px;
    position: absolute;
    top: -4px;
    left: -1px;
  }
  &:after {
    content: "";
    width: 8px;
    height: 8px;
    background: #B3B3B3;
    border-radius: 16px;
    position: absolute;
    top: -4px;
    right: -1px;
  }
}

@include media-breakpoint-up(md) {
}
@include media-breakpoint-up(lg) {
  .nav-item,.nav-link {
    height: auto;
    min-height: auto;
    border-bottom: none;
  }
  .header-line {
    display: block;
  }
  .nav-menu {
    position: relative;
    top: auto;
    left: auto;
    z-index: 1500;
    width: auto;
    height: auto;
    transform: initial;
    transition: all .3s linear;
    padding-top: 0;
    overflow: visible;
    background: #fff;
    &.is-active {
      transform: translateX(0);
    }
  }
  .hamburger {
    display: none;
  }
  .h-header {
    width: 100%;
    height: 68px;
  }
  .position-nav-link {
    position: absolute;
    top: 64%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .position-hamburger {
    background: none;
  }
  .nav-link {
    color: #000;
    transition: all 0.5s ease-out;
    display: block;
    padding: 0.5rem 1rem;
    &:hover {
      color: #fff;
    }
  }
}
@include media-breakpoint-up(xl) {
}
